<template>
  <v-navigation-drawer
    app
    :value="value"
    @input="$emit('input', $event)"
    fixed
    right
    width="320"
    temporary
    color="primary"
    dark
    :style="{ 'z-index': 9999 * 9999 }"
  >
    <bg opacity=".8" color="primary"></bg>
    <v-card
      height="100%"
      :loading="loading"
      elevation="0"
      class="mx-auto pa-2 transparent overflow-hidden"
      max-width="400"
      dark
    >
      <v-card-text>
        <v-alert type="error" v-if="error" v-text="error.message"></v-alert>
        <v-list v-if="Object.keys(profile).length > 0">
          <v-list-item ripple class="pa-2 mb-2">
            <v-list-item-avatar>
              <v-img
                v-if="profile.profile"
                class="white--text align-end rounded-0"
                :src="profile.profile"
              >
              </v-img>
              <v-avatar :color="getRandomColor()">
                <span class="white--text headline" v-if="profile.full_name">{{
                  (profile.full_name.charAt(0) + profile.full_name.charAt(1))
                    | uppercase
                }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-title>{{
              profile.full_name | uppercase
            }}</v-list-item-title>
            <v-btn @click="$emit('input', false)" right icon
              ><v-icon>fas fa-times</v-icon></v-btn
            >
          </v-list-item>
        </v-list>
        <v-alert
          v-else
          dense
          style="position:relative;"
          type="warning"
          width="100%"
          >No Profile<v-btn
            @click="fetchUserProfile"
            class="ref_btn"
            :loading="loading"
          >
            <v-icon>fas fa-redo</v-icon>
          </v-btn></v-alert
        >
        <v-divider class="white"></v-divider>
        <v-list
          v-if="
            routes.filter(r => !r.children || r.children.length === 0).length >
              0
          "
          nav
          dense
          class="pl-0 pr-0"
        >
          <v-list-item
            v-for="(route, routeIndex) in routes.filter(
              r => !r.children || r.children.length === 0
            )"
            :key="routeIndex"
            link
            exact
            :to="{ name: route.name }"
          >
            <v-list-item-icon>
              <v-icon>fas fa-{{ route.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ route.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <!--        <v-expansion-panels flat hover tile class="transparent">-->
        <!--          <v-expansion-panel-->
        <!--            class="transparent"-->
        <!--            v-for="(route, index) in routes.filter(-->
        <!--              r => r.children && r.children.length > 0-->
        <!--            )"-->
        <!--            :key="index"-->
        <!--          >-->
        <!--            <v-expansion-panel-header class="pl-3" color="transparent" ripple>-->
        <!--              <v-icon style="max-width: min-content" size="25" class="mr-8"-->
        <!--                >fas fa-{{ route.icon }}</v-icon-->
        <!--              >-->
        <!--              {{ route.label }}-->
        <!--            </v-expansion-panel-header>-->
        <!--            <v-expansion-panel-content>-->
        <!--              <v-list nav dense class="pl-0 pr-0">-->
        <!--                <template-->
        <!--                  v-for="(childRoute, childRouteIndex) in route.children"-->
        <!--                >-->
        <!--                  <v-list-item-->
        <!--                    :key="childRouteIndex"-->
        <!--                    link-->
        <!--                    exact-->
        <!--                    :to="{ name: childRoute.name }"-->
        <!--                  >-->
        <!--                    <v-list-item-icon>-->
        <!--                      <v-icon>fas fa-{{ childRoute.icon }}</v-icon>-->
        <!--                    </v-list-item-icon>-->
        <!--                    <v-list-item-title>{{-->
        <!--                      childRoute.label-->
        <!--                    }}</v-list-item-title>-->
        <!--                  </v-list-item>-->
        <!--                </template>-->
        <!--              </v-list>-->
        <!--            </v-expansion-panel-content>-->
        <!--          </v-expansion-panel>-->
        <!--        </v-expansion-panels>-->
        <dis-assoc-drop
          background="white"
          color="secondary"
          v-if="$vuetify.breakpoint.mobile && isMgmt"
        ></dis-assoc-drop>
        <v-list dark>
          <!--          <refresh_button-->
          <!--            @finished="$emit('input', false)"-->
          <!--            tag="list"-->
          <!--            class="accent mt-1 mb-1"-->
          <!--            all-->
          <!--          />-->
          <v-list-item class="secondary mt-1 mb-1" ripple @click="logout">
            <v-list-item-icon
              ><v-icon>fas fa-door-open</v-icon></v-list-item-icon
            >
            <v-list-item>Logout</v-list-item>
          </v-list-item>
          <update_version />
        </v-list>
        <v-list-item-subtitle
          v-text="label"
          class="text-center"
        ></v-list-item-subtitle>
        <!--        <v-btn block tile ripple class="mt-2 mb-2" @click="">-->
        <!--          <v-icon>fas fa-redo</v-icon>-->
        <!--          Refresh All Data-->
        <!--        </v-btn>-->
        <!--        <v-btn color="secondary" class="mt-2 mb-2" block tile ripple >-->
        <!--          <v-icon>fas fa-door-open</v-icon>-->
        <!--          Logout-->
        <!--        </v-btn>-->
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
const { version } = require("@/../package.json");
export default {
  name: "user_drawer",
  components: {
    disAssocDrop: () => import("@/components/disAssocDrop"),
    update_version: () => import("@/components/update_version"),
    Bg: () => import("@/components/bg")
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      routes: [],
      label: `version:${version}`
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchUserProfile();
    });
    this.routes = this.lauderGetRoutes();
  },
  methods: {
    async fetchUserProfile() {
      this.loading = true;
      if (Object.keys(this.profile).length > 0) {
        this.loading = false;
        return;
      }
      await this.$store.dispatch("lauderFetchUserData");
      this.loading = false;
    },
    async logout() {
      try {
        this.loading = true;
        this.$emit("input", false);
        await this.$store.dispatch("lauderlogout");
      } catch (e) {
        this.error = e;
      }
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters({ profile: "laudergetLoginProfile", isMgmt: "isMgmt" })
  }
};
</script>

<style scoped>
.ref_btn {
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
  position: absolute;
}
</style>
